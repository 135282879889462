export default function () {
  let clarityId = ''

  const mirrorName = document.body.dataset.site
  const isLocalEnv = window.location.host.includes('localhost')

  switch (mirrorName) {
    case 'review_27':
      clarityId = 'qxxz93hwnp';
      break;
    default:
      break;
  }

  if (clarityId && !isLocalEnv) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;

    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${clarityId}");
    `;

    document.head.appendChild(script);
  }
}
