import scroll from './helpers/smooth-scroll.js';
import burger from "./modules/burger-menu";
import accordion from "./modules/questions";
import blogSlider from "./modules/blog-post";
import quiz from "./modules/quiz";
import catalog from "./modules/catalog";
import rate from "./modules/rate";
import utm from "./modules/utm";
import date from "./modules/update-date";
import promoDate from "./modules/update-promo-year";
import ethnicSearchParams from "./modules/ethnicSearchParams";
import getDynamicMirrorNameAndLink from "./modules/dymamicLinks";
import gtm from "./modules/gtm";
import clarity from "./modules/clarity";
import blogAccordion from "./modules/posts";
import blog from "./modules/blog";
import btnScroll from "./modules/btnScroll"
import marketingParams from "./modules/marketingParams"
import changePreLanStep from "./preLand/changePreLanStep";
import circleProgressRate from "./modules/circleProgressRate";
import disclaimers from "./modules/disclaimers";

const isQuizPage =  window.location.href.includes('quiz')

function main() {
    accordion()
    blogSlider()
    rate()
    quiz()
    catalog()
    blogAccordion()
    btnScroll()
    circleProgressRate()
    disclaimers()

    if (isQuizPage) {
        changePreLanStep()
    }
}

burger()
scroll()
utm()
promoDate()
getDynamicMirrorNameAndLink()
blog()

if (document.documentElement.clientWidth < 480) {
    window.addEventListener('load', function () {
        requestAnimationFrame(main);
    });

    if (isQuizPage) {
        changePreLanStep();
    }
} else {
    main();
}

date()
ethnicSearchParams()
marketingParams()
gtm()
clarity()
